import React from "react"

const TermsOfServiceEN = () => {
  return (
    <div style={{ fontSize: 16, flex: 5 }}>
      <p>
        By using our Services or conducting the transactions with us, Customer
        is deemed to consent to these terms and conditions specified in this
        Term of Service. Therefore, We encourage Customer to read carefully all
        the contents described in this Term of Service before deciding to use
        our Service or conduct the transactions with us.
      </p>
      <p>
        We reserve the right to change or adjust the content of this Term of
        Service at any time without the notification. The changes or new Term of
        Service will be posted publicly on our platform. If Customer continues
        to use our Services or conduct the transaction with us, Customer is
        deemed to consent to the changes and/ or new Term of Service. Therefore,
        We encourage Customer to check our Term of Service frequently to update
        our terms and conditions promptly.
      </p>
      <strong>
        1. THE CONDITIONS TO USE OUR SERVICE OR CONDUCT THE TRANSACTIONS WITH US
      </strong>
      <p>
        For the purposes of using our Services and/ or conducting the
        transactions with us, Customer must comply with the following conditions
        and requirements:
      </p>
      <p>
        a. Customer must be eighteen years of age or older and have full legal
        capacity. In the event that Customer is under 18 years of age or lacks /
        limits legal capacity, all transactions or requests for using the
        Services by the Customer must be agreed and supervised by parent or
        guardian legal in accordance with current laws.
      </p>
      <p>
        b. Customer must ensure the accuracy and authenticity of all information
        providing to us and take all responsibilities for Customers’
        information.
      </p>
      <p>
        c. Customer understands that email address of Customer is the main way
        to exchange information and communicate between Customer and us,
        Customer agrees to receive the information of our promotions or
        advertisements sent to Customers’ email.
      </p>
      <p>
        d. In some event, Customer will be provided an Account to log into some
        functions of our platform, Customer has obligations to keep Customers’
        password confidential. We will not take responsibility for any damages
        or loses occurring from the disclosed/ stolen password or unauthorized
        access/ use of Customers’ Account.
      </p>
      <p>
        e. We have full right to terminate the transactions with Customer and
        withdraw the Account or lock Customers’ access immediately without the
        notification if Customer violates any terms or conditions as specified
        in our Term of Service.
      </p>
      <p>
        <strong>2. THE OBLIGATIONS OF CUSTOMER</strong>
      </p>
      <p>
        <strong>
          <em>2.1. Customer shall not perform the following activities:</em>
        </strong>
      </p>
      <p>
        a. Uploading or transmitting any content that is unlawful, threatening,
        abusive, harassing, alarming, tortuous, defamatory, vulgar, libelous,
        infringing the personal rights, violating the culture and morals, or any
        inaccuracy information through any way;
      </p>
      <p>
        b. Violating any laws or any regulations of our Policy or Term of
        Service;
      </p>
      <p>c. Impersonating any person or organization;</p>
      <p>
        d. Falsifying the documents or giving the fraud information to us or our
        affiliates;
      </p>
      <p>
        e. Performing activities to hack or damage our system, or steal
        information from our platform, or modify/ remove the unauthorized
        information on our platform;
      </p>
      <p>
        f. Exploiting or collecting any information relating to our customers,
        partners or affiliates;
      </p>
      <p>
        g. Uploading in any way the content or information that Customer is not
        authorized or not fully authorized, or infringes the rights of any other
        third parties, including but not limitation the confidential
        information, internal information, intellectual properties;
      </p>
      <p>
        h. Uploading or transmitting in any way any unauthorized advertising or
        promotional information, spam (spam), or any information contrary to the
        current laws;
      </p>
      <p>
        i. By any way, uploading or transmitting any material that contains
        software viruses, files or programs designed to interfere with,
        manipulate, interrupt, destroy or limit the functionality or integrity
        of any computer software or hardware or data or telecommunications
        equipment of the computer;
      </p>
      <p>
        j. Interfering with, manipulating or disrupting the servers or networks
        connected to our platform to affect our operations and transactions with
        users;
      </p>
      <p>
        k. Taking advantage of our Services or transactions with us to perform
        the illegal activities, including but not limitation: money laundering,
        terrorism, illegal trade;
      </p>
      <p>l. Infringing our rights.</p>
      <p>
        <strong>
          <em>2.2. Customer understands and agrees that:</em>
        </strong>
      </p>
      <p>
        a. Customer is solely responsible for the information and content
        posted, submitted, or provided by Customer in any way or means on our
        platform. Customer also understands that during the use of our platform,
        Customer may encounters the content, information or images that Customer
        thinks they are not appropriate.
      </p>
      <p>
        b. Customer shall allow us or our affiliates or other appointed parties
        to screen, delete, remove or reject the content, information or images
        uploaded on the our platform (regardless of the content, information or
        images uploaded by any party). We reserve the right to immediately
        remove without prior notification if Customers’ content, information or
        images (i) violate our Terms of Service or any of our policies or rules;
        or (ii) are claimed by any third party; or (iii) infringes the rights of
        any third party. In addition, We reserve the right to suspend or block
        Customers’ Account or terminate all services / transactions with
        Customer.
      </p>
      <p>
        c. Customer shall agree to allow us to access, maintain and disclose
        Customers’ personal information (i) if required by law or by any
        regulatory jurisdiction; or (ii) to perform the services / transactions
        with Customer; or (iii) to resolve or respond the complaints or requests
        of third parties.
      </p>
      <p>
        <strong>3. LIMITATIONS OF LIABILITY</strong>
      </p>
      <p>
        a. We have not guarantee that our Service will be not interrupted or
        completely secure or not occur any error.
      </p>
      <p>
        b. We have not guarantee that the results of our Services or
        transactions with us will be completely accurate and fully meet the
        Customers’ expectations, We will try to correct the error by our
        reasonable efforts.
      </p>
      <p>
        c. Customer acknowledges that Customer will take responsibility for all
        risks arising out of the scope of use and operation on our platform.
      </p>
      <p>
        d. Customer must understand and agree that in no event that We will be
        responsible for any damages, whether direct, indirect or incidental,
        especially damages of profits, reputation, use rights, data or other
        intangible damages relating on Customers’ transactions with third
        parties (including websites / platforms linked to our platform).
      </p>
      <p>
        <strong>4. THE INTELLECTUAL PROPERTY RIGHTS</strong>
      </p>
      <p>
        a. By providing Customers’ intellectual property, Customer acknowledges
        that Customer has permitted us to use Customers’ intellectual property
        to conduct the Services for Customers. These intellectual property
        assets still belong to Customer, therefore Customer is fully responsible
        for any arising problems relating to the intellectual property provided
        by Customer.
      </p>
      <p>
        b. These platform includes, but is not limited to: the website, the
        application and all content on the platform (including but not
        limitation to: texts, designs, graphics, interfaces, images, code,
        information, trademark, logo, industrial design, brand name) are either
        owned by us or licensed to us by a third party, and are protected in
        accordance with current Vietnamese laws in particular and the world law
        in general.
      </p>
      <p>
        c. All activities of copying, quoting, creating derivative versions,
        distributing, publishing or circulating for any purposes in any form
        must be prior to our written consent. If there is an unauthorized use of
        our intellectual property, we reserve the right to request the user to
        terminate the use immediately and to request the compensation.
      </p>
      <p>
        <strong>5. NON-DISCLOSURE</strong>
      </p>
      <p>
        In the event that Customer is provided an Account to use the Services
        and / or perform a transaction with us, Customer must keep the password
        and all information relating to transactions with us confidential.
        Regarding to the information security, please read carefully the
        regulations specified in our Privacy Policy.
      </p>
      <p>
        <strong>6. GOVERNING LAW</strong>
      </p>
      <p>
        This Terms of Service shall be governed by and construed in accordance
        with the Vietnamese laws. Any dispute or claim arising out of or
        relating to this Terms of Service shall be resolved by Vietnam
        International Arbitration Centre at the Vietnam Chamber of Commerce and
        Industry (VIAC). The language of the arbitration shall be Vietnamese.
      </p>
      <p>
        <strong>7. THE EFFECTIVE DATE</strong>
      </p>
      <p>
        This Term of Service shall come into effect from …/…/2020. We shall have
        right to change, adjust or add the content of this Term of Service at
        any time. The changes or adjustments will be updated and published on
        our platform, and shall be take into effect from the time that the
        changes or adjustments have been published on our platforms.
      </p>
    </div>
  )
}

export default TermsOfServiceEN
